<template>
  <section class="vh-100 subscribe-page">
    <div class="overlay">
      <!-- Logo at the top -->
      <div class="logo-container">
        <img
          src="../assets/images/makeping-logo1.png"
          alt="MakePing Logo"
          class="logo"
        />
      </div>

      <div class="subscribe-container">
        <h1 class="heading">Subscribe to Stay Connected</h1>
        <p class="subheading">Enter your email below to get started.</p>
        <div class="input-group">
          <input
            v-model="email"
            type="email"
            placeholder="Your email address"
            class="form-control"
            autocomplete="email"
            required
          />
          <button class="btn btn-submit" @click="handleSubscribe" :disabled="loading">
            Submit
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import UserService from "./../../services/userController";

export default {
  name: "subscribe",
  data() {
    return {
      email: "",
      loading: false
    };
  },
  methods: {
    async handleSubscribe() {
      try {
        this.loading = true;
        const planId = this.$route.query.planId;
        const response = await UserService.subscribe(planId, this.email);
        if (response.result) {
          this.$toasted.show(response.message, {
            theme: "outline",
            position: "bottom-center",
            type: "success",
            duration: 2000
          });
          window.location.href = response.data;
        } else {
          this.$toasted.show(response.message, {
            theme: "outline",
            position: "bottom-center",
            type: "error",
            duration: 2000
          });
        }
      } catch (error) {
        console.error("Error during subscription:", error);
        this.$toasted.show("An error occurred. Please try again.", {
          theme: "outline",
          position: "bottom-center",
          type: "error",
          duration: 2000
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style>
body {
  background-image: url("../assets/images/login-bg.jpg") !important;
}


.subscribe-page .overlay {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.logo-container {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.logo {
  height: 8vh;
}

.subscribe-container {
  background: transparent;
  width: 100%;
  max-width: 600px;
  text-align: center;
  margin-bottom: 40vh; 
}

.heading {
  font-size: 2.5rem;
  font-weight: 900;
  color: #fff;
  margin-bottom: 10px;
}

.subheading {
  font-size: 1.2rem;
  color: #ddd;
  margin-bottom: 30px;
}

.btn-submit {
  background-color: #ff9100;
  color: #fff;
  padding: 7px 20px;
  border-radius: 0 5px 5px 0;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.input-group .btn-submit:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.input-group .btn-submit:hover:not(:disabled) {
  background-color: #e6870e;
}
</style>
